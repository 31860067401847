<template>
  <div>
    <div class="control-options">
      <grouped-options
        :selected-option="selectedGrouping"
        :options="groupingOptions"
        @update:handleSelect="handleDataGrouping"
      />
      <div class="right-side-control-group">
        <eewc-select-box
          class="right-side-control-group__dropdown"
          :items="eventTypesDropDownList"
          :value="selectedEventType"
          :disabled="isDropdownDisabled"
          :loading="loading"
          @input="handleEventTypeChange"
        />
        <refresh-button
          :tool-tip-text="t('Refresh')"
          :loading="eventMetricsStore.loadingEventMetricsData"
          @click="getEventMetricsData"
        />
        <eewc-skeleton
          v-if="eventMetricsStore.loadingEventMetricsData"
          type="big-icon"
        />
        <eewc-dropdown-menu
          v-else
          :menu-items="dropdownMenuItems"
          :tooltip="t('More')"
          @select="dropdownMenuAction"
        />
      </div>
    </div>
    <highcharts
      :key="chartColors.length"
      ref="highChartsRef"
      constructor-type="stockChart"
      :options="chartData"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue';

import { SelectBoxItem } from '@eencloud/eewc-components/src/components/dropdowns/types';

import { t, tc } from '@/plugins/i18n.ts';
import router from '@/service/router';
import {
  useAppStateStore,
  useCamerasSettingsStore,
  useCamerasStore,
  useEventMetricsStore,
  useEventsStore,
} from '@/stores';

import seriesBaseConfig from '../config/seriesBaseConfig';
import { getChartOptionsBaseConfig, getMinAndMaxDate } from './chartUtils';
import GroupedOptions from './GroupedOptions.vue';
import RefreshButton from './RefreshButton.vue';

type ActionType = 'print-chart';

const ONE_HOUR_INTERVAL = 1;
const SIX_HOUR_INTERVAL = 6;
const TWELVE_HOUR_INTERVAL = 12;
const TWENTY_FOUR_HOUR_INTERVAL = 24;

const groupingOptions = [
  { label: t('{hour}h', { hour: ONE_HOUR_INTERVAL }), value: ONE_HOUR_INTERVAL },
  { label: t('{hour}h', { hour: SIX_HOUR_INTERVAL }), value: SIX_HOUR_INTERVAL },
  { label: t('{hour}h', { hour: TWELVE_HOUR_INTERVAL }), value: TWELVE_HOUR_INTERVAL },
  { label: t('{hour}h', { hour: TWENTY_FOUR_HOUR_INTERVAL }), value: TWENTY_FOUR_HOUR_INTERVAL },
];

type DataPoint = [number, number];

const REQUIRED_EVENT_TYPE_LIST = [
  'een.motionDetectionEvent.v1',
  'een.loiterDetectionEvent.v1',
  'een.tamperDetectionEvent.v1',
  'een.objectIntrusionEvent.v1',
  'een.objectLineCrossEvent.v1',
];

const DROP_DOWN_MAP = {
  'een.motionDetectionEvent.v1': t('Motion'),
  'een.loiterDetectionEvent.v1': t('Loiter'),
  'een.tamperDetectionEvent.v1': t('Tampering'),
  'een.objectIntrusionEvent.v1': t('Intrusion'),
  'een.objectLineCrossEvent.v1': t('Line crossing'),
};

const eventsStore = useEventsStore();
const eventMetricsStore = useEventMetricsStore();
const camerasStore = useCamerasStore();
const cameraSettingsStore = useCamerasSettingsStore();
const appStateStore = useAppStateStore();

const eventData = ref<DataPoint[] | undefined>();
const highChartsRef = ref();
const label = ref<string>('events');
const loading = ref(false);
const offsetDate = ref<Date>();
const retentionDays = ref<number>();
const selectedEventType = ref(REQUIRED_EVENT_TYPE_LIST[0]);
const selectedGrouping = ref(ONE_HOUR_INTERVAL);
const supportedEventTypes = ref<string[]>([]);
const title = ref<string>('');
const chartColors = ref<string[]>([]);

const chartData = computed(() => {
  const isAnalyticEvent = chartColors.value.length > 0;

  if (isAnalyticEvent) {
    return {
      ...chartOptions.value,
      colors: chartColors.value,
    };
  }

  return chartOptions.value;
});

const dropdownMenuItems = computed(() => {
  return [{ name: t('Print chart'), value: 'print-chart', disabled: !hasData.value }];
});

const hasData = computed(() => !!eventData.value?.length);
const deviceId = computed<string>(() => {
  return router.currentRoute.params.id;
});
const chartOptions = computed(() => Object.assign({}, getChartOptionsBaseConfig(label.value, title.value)));
const eventTypesDropDownList = computed(() => {
  return supportedEventTypes.value.reduce((eventList, event) => {
    // Check if the event is in the required list
    if (REQUIRED_EVENT_TYPE_LIST.includes(event)) {
      eventList.push({
        text: DROP_DOWN_MAP[event as keyof typeof DROP_DOWN_MAP],
        value: event,
      });
    }
    return eventList;
  }, [] as SelectBoxItem[]);
});
const isDropdownDisabled = computed(() => eventTypesDropDownList.value.length === 1);

function dropdownMenuAction(action: ActionType) {
  switch (action) {
    case 'print-chart':
      highChartsRef.value.chart && highChartsRef.value.chart.print();
      break;
  }
}
async function setTopBarTitle() {
  const camera = await camerasStore.getCamera(deviceId.value);

  appStateStore.changeTopBarBreadcrumbs(
    Object.assign({}, appStateStore.topBarBreadcrumbs, {
      title: camera?.name ? `${t('Event metrics')} • ${camera.name}` : t('Event metrics'),
    })
  );
}

async function getEventMetricsData() {
  const currentDate = new Date();
  const timestamp__lte = currentDate.toEENApiDateTime();
  if (!retentionDays.value) {
    await getCloudRetentionDays();
  }
  offsetDate.value = getChartStartDate(retentionDays.value);
  const timestamp__gte = offsetDate.value && offsetDate.value.toEENApiDateTime();

  const params = {
    actor: `camera:${deviceId.value}`,
    eventType: selectedEventType.value,
    timestamp__lte,
    timestamp__gte,
  };

  const eventMetricsData = await eventMetricsStore.getEventMetrics(params);
  if (eventMetricsData && Array.isArray(eventMetricsData) && eventMetricsData.length > 0) {
    const haveEvents = eventMetricsData[0]?.dataPoints.every((dataPoint: number[]) => dataPoint[1] !== 0);
    eventData.value = haveEvents ? eventMetricsData[0]?.dataPoints : [];
  } else {
    eventData.value = [];
  }

  renderMetrics();
}

async function getCloudRetentionDays() {
  const cameraSettingsData = await cameraSettingsStore.fetchCameraSettings(deviceId.value);
  retentionDays.value = cameraSettingsData?.data.retention?.cloudDays;
}

function getChartStartDate(daysBefore: number | undefined) {
  if (typeof daysBefore === 'number' && daysBefore > 0) {
    const date = new Date();
    date.setDate(date.getDate() - daysBefore);
    return date;
  }
}

function renderMetrics() {
  const newData: DataPoint[] | undefined = eventData.value;
  label.value = '';
  const series = [getSeriesConfig(tc('events', newData?.length), newData)];

  const { min, max } = getMinAndMaxDate(newData, offsetDate.value);

  const isAnalyticEvent = selectedEventType.value !== REQUIRED_EVENT_TYPE_LIST[0];
  const COLOR_FOR_ANALYTIC_EVENT = ['#C0DF16'];

  chartColors.value = isAnalyticEvent ? COLOR_FOR_ANALYTIC_EVENT : [];
  title.value = !hasData.value ? t('No data available') : '';

  chartOptions.value.xAxis = {
    ...chartOptions.value.xAxis,
    min,
    max,
  };

  chartOptions.value.series = series;
  highChartsRef.value.chart.update(chartOptions.value, true, true);
}

function handleDataGrouping(intervalInHours: number) {
  selectedGrouping.value = intervalInHours;
  const chart = highChartsRef.value.chart;
  const dataGroupingConfig = getDataGroupingConfig(intervalInHours);

  if (chart?.series.length) {
    chart.series[0].update({ dataGrouping: dataGroupingConfig }, false);
    chart.redraw();
  }
}

function getDataGroupingConfig(intervalInHours: number) {
  return {
    enabled: true,
    approximation: 'sum',
    forced: true,
    units: [['hour', [intervalInHours]]],
  };
}

function getSeriesConfig(name: string, data: [number, number][] | undefined) {
  const dataGroupingConfig = getDataGroupingConfig(selectedGrouping.value);
  return Object.assign({}, seriesBaseConfig, {
    name,
    data,
    dataGrouping: dataGroupingConfig,
    tooltip: {
      valueDecimals: 0,
    },
  });
}

function handleEventTypeChange(option: { value: string; text: string }) {
  selectedEventType.value = option.value;
  getEventMetricsData();
}

async function getSupportedEventTypes() {
  loading.value = true;
  supportedEventTypes.value = await eventsStore.getDeviceSupportedEventTypes([deviceId.value]);
  loading.value = false;
}

onMounted(async () => {
  setTopBarTitle();
  await getSupportedEventTypes();
  await getCloudRetentionDays();
  getEventMetricsData();
});

watch(
  () => eventMetricsStore.loadingEventMetricsData,
  (newVal, oldVal) => {
    const chart = highChartsRef.value?.chart;
    if (newVal && newVal !== oldVal) {
      chart?.showLoading(t('Loading data...'));
      title.value = '';
    } else {
      chart?.hideLoading();
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
@import '@/styles/public/main.scss';

.control-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 64px;
}

.selected-button {
  color: $accentFixed;
  background-color: $accentLight;
  border-radius: 4px;
}

.right-side-control-group {
  display: flex;
  gap: 12px;
  align-items: center;
  &__dropdown {
    width: 200px;
  }
}

.data-grouping-buttons {
  display: flex;
  background-color: $backgrounds;
  padding: 12px 0px;
  border-radius: 4px;
  border: 1px solid $elements;
}

.data-grouping-buttons button {
  flex: 1;
  background-color: transparent;
  margin: 0 8px;
  padding: 0 4px;
  border: none;
  font-size: $font-size-root;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
}

.data-grouping-buttons button.active {
  background-color: $accentLight;
  color: $accent;
}

.data-grouping-buttons button:hover {
  background-color: $accentLight;
}

.data-grouping-buttons .button-parent {
  display: flex;
  width: 62px;
}

.data-grouping-buttons .button-parent:not(:last-child) {
  border-right: 1px solid $elements;
}
</style>
