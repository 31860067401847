import { ref } from 'vue';
import { defineStore } from 'pinia';
import api from '@eencloud/eewc-components/src/service/api';
import { GetEventMetricsParams } from '@eencloud/eewc-components/src/service/api-types';

export const useEventMetricsStore = defineStore('eventMetrics', () => {
  const loadingEventMetricsData = ref(false);

  async function getEventMetrics(params: GetEventMetricsParams) {
    loadingEventMetricsData.value = true;
    const data = await api.getEventMetrics(params);

    loadingEventMetricsData.value = false;
    return data;
  }

  return { getEventMetrics, loadingEventMetricsData };
});
